import React from 'react';
import LogoInterface from './logo.interface';


import dynamic from 'next/dynamic';
const Button = dynamic(() => import('@mui/material/Button').then(module => module))
const Image = dynamic(() => import('next/image').then(module => module))

function Logo({ onClick, active, height, width, name }: LogoInterface) {

    return (
        <>

            <Button onClick={onClick} className={active == 'home' ? 'logo-curve' : ''}>
                <Image
                    priority={true}
                    src={name == "white" ? "/static/images/logo-white.png" : "/static/images/logo.png"}
                    alt={"Diabafrica logo"}
                    width={width} height={height} />
            </Button>

        </>
    );
}

export default Logo;