import mixpanel from 'mixpanel-browser';
import { getSession } from "next-auth/react";

mixpanel.init(process.env.MIXPANEL_TOKEN + "");
let env_check = true;// process.env.GLOBAL_ENV === 'production';

let actions = {
  identify: (id: string) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name: string, props: any) => {
    if (env_check) mixpanel.track(name, props);
  },
  time_event: (name: string) => {
    if (env_check) mixpanel.time_event(name);
  },
  people: {
    set: (props: any) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
  gatherInfo: async () => {
    const session = await getSession();
    if (session?.user) {
      mixpanel.identify(session.user?.id + "");
      mixpanel.people.set({
        first_name: session.user.first_name,
        last_name: session.user.last_name,
        speciality: session.user.speciality,
        email: session.user.email,
        Email: session.user.email,
        user_country: session.user.country
      });
    }
  }
};

export let Mixpanel = actions;