import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import Logo from "../logo";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
const CloseIcon = dynamic(() =>
  import("@mui/icons-material").then((module) => module.Close)
);
type PopupType = {
  title: string;
  subtitle: string;
  thumbnail: string;
  slug?: string;
  url?: string;
};

type ModalType = {
  item: PopupType;
  status: boolean;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: 500, xs: "100%" },

  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  overflowY: "auto",
  borderRadius: 5,
  borderColor: "#ffffff",
};

function ItemModal({ item, status }: ModalType) {
  const { t, lang } = useTranslation();
  const router = useRouter();

  const [open, setOpen] = useState(status);
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box
          sx={{
            ...style,
            width: { md: 500, xs: "80%" },
            maxWidth: { md: 500, xs: "100%" },
          }}
        >
          <Box sx={{ position: "relative" }}>
            <Grid
              sx={{ position: "absolute", top: 0, right: 0, zIndex: 10000 }}
            >
              <IconButton aria-label="cloae modal" size="large" onClick={handleClose}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Box>
          <Grid
            mt={1}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ width: "100%" }}
          >
            <Grid
              sx={{
                width: "100%",
                minHeight: { md: 240, xs: 200 },
                maxHeight: { md: 270, xs: 200 },
                backgroundImage: "url(/static/images/popup-background.png)",
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Grid
                sx={{
                  position: "relative",
                  clear: "both",
                  mb: 5,
                  mt: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
                className={"popup-curve"}
              >
                <Logo
                  name={"light"}
                  onClick={() => {}}
                  active={""}
                  width={150}
                  height={22}
                />
              </Grid>

              <Typography
                mb={1}
                variant="h5"
                component={"h2"}
                color={"primary"}
                fontWeight={"bold"}
                sx={{ textAlign: "center", fontSize: { md: 22, xs: 15 } }}
              >
                {item?.title}
              </Typography>
            </Grid>

            <Grid
              mt={1}
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: { md: 400, xs: "100%" },
                height: { md: 350, xs: 350 },
                mt: -12,
                zIndex: 0,
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Image
                priority={true}
                src={item?.thumbnail}
                alt={item?.title}
                fill
                style={{ objectFit: "contain" }}
              />
            </Grid>
            {item?.url ? (
              <Button
                color="primary"
                variant="contained"
                className="font-bold"
                onClick={() => {
                  console.log(item);
                  if (item.url) {
                    if (item.slug) {
                      router.push(item.url + "/q?s=" + item.slug);
                    }
                    router.push(item.url);
                  }
                }}
                sx={{
                  mt: 3,
                  width: 0.5,
                  color: "#ffffff",
                  ml: 1,
                  border: 2,
                  borderColor: "#0A2C58",
                }}
                size="large"
              >
                {t("common:view_more")}
              </Button>
            ) : null}
          </Grid>
        </Box>
      </>
    </Modal>
  );
}

export default ItemModal;
